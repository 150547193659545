.gallery-container {
  width: 90%;
  margin: auto;
  padding: 0px 0px;

  .link-gallery-card-body {
    padding: .5em;
  }

  .link-gallery-button-link {
    margin-right: 0px !important;
    font-size: 14px !important;
  }
}

.document-gallery-item-card {
  border: 1px solid black;
  padding: 10px 0px;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.5px;
}

.document-gallery-item-card {
  padding: 20px 10px;

  h4 {
    text-align: center;
    font-size: 16px !important;
    letter-spacing: 0px;
  }
}
