$main-color: #316bbe;

.reverse-row {
  flex-direction: row-reverse;
}

.interior-section-component {
  margin: auto;
}

.interior-row {
  width: 80%;
  margin: auto;

  .vertically-center {
    display: flex;
  }
}

.interior-row-container {
  margin: auto;
}

section {
  max-width: 100%;


  .right-column {
    margin-top: 50px;
  }

  .left-column {
    margin-top: 50px;
  }

  .left-column-content {
    padding: 30px;
  }

  .right-column-content {
    padding: 30px;
  }

  .img-fluid{
   // height: 100%;
  }
}

.dash:after {
  background-color:$main-color;
}

.interior-row img {
  object-fit: cover;
}
